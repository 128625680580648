import React from "react";
import { Link, graphql } from "gatsby";
import LandingPage from "src/templates/landing-page";

export const query = graphql`
  query TaggedQuery {
    page: sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }) {
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
    projects: allSanityProject(sort: { order: ASC, fields: order }) {
      edges {
        node {
          _id
          content: _rawContent(resolveReferences: { maxDepth: 10 })
        }
      }
    }
    filterSettings: sanityFilterSettings {
      enableTagFilters
      tagFilterHeading
      enableArtistFilters
      artistFilterHeading
    }
    tags: allSanityTag {
      edges {
        node {
          _id
          slug {
            current
          }
          title
        }
      }
    }
    artists: allSanityArtist {
      edges {
        node {
          _id
          slug {
            current
          }
          name
        }
      }
    }
  }
`;

const TaggedPage = props => {
  const { location, data } = props;
  const content = props.data.page.content;
  const projects = props.data.projects.edges;
  const tags = props.data.tags.edges;
  const artists = props.data.artists.edges;
  const filterSettings = props.data.filterSettings;
  return (
    <LandingPage
      location={location}
      pageContext={content}
      projects={projects}
      filterTags={{ work: tags, artists: artists }}
      filterSettings={filterSettings}
    />
  );
};

export default TaggedPage;
